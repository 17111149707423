import { translator } from 'config/i18n';
import config from 'config';
import * as routes from './routes';

export interface NavLinkProps {
  label: string;
  path: string;
  icon: string;
  image?: string;
  secondaryLinks?: Omit<NavLinkProps, 'icon' | 'secondaryLinks'>[];
  disabled?: boolean;
  external?: boolean;
}

export interface PublicNavLinkProps {
  label: string;
  path: string;
  external?: boolean;
  hasDropdown?: boolean;
  secondaryLinks?: Omit<PublicNavLinkProps, 'secondaryLinks'>[];
}

// const aboutusNavLinks: NavLinkProps = {
//   label: 'ABOUT US',
//   path: routes.ABOUT_US,
//   icon: 'about-us',
//   secondaryLinks: [
//     {
//       label: 'About Us',
//       path: '/Info/AboutUs',
//     },
//     {
//       label: 'Join Us',
//       path: '/Info/JoinUs',
//     },
//     {
//       label: 'Blog',
//       path: 'https://medium.com/@Husslup',
//       external: true,
//     },
//     {
//       label: 'Podcast',
//       path: 'http://podcast.husslup.com',
//       external: true,
//     },
//   ],
// };

// const marketPlaceRoute: NavLinkProps = {
//   label: translator.t('MENTOR MARKETPLACE'),
//   path: routes.MENTOR_MARKETPLACE,
//   icon: 'marketplace',
// };

export const navLinks: NavLinkProps[] = [
  {
    label: translator.t('Discover'),
    path: routes.DISCOVER,
    icon: 'discover',
    secondaryLinks: [
      {
        label: translator.t('People'),
        path: routes.DISCOVER_PEOPLE,
      },
      {
        label: translator.t('Companies'),
        path: routes.DISCOVER_COMPANIES,
      },
    ],
  },
  {
    label: translator.t('Staffing'),
    path: routes.STAFFING,
    icon: 'staffing',
    secondaryLinks:
      config.enableStaffingRoster === 'true'
        ? [
            {
              label: translator.t('ProSearch'),
              path: routes.STAFFING_PRO_SEARCHES,
            },

            {
              label: translator.t('Staffing Rosters'),
              path: routes.STAFFING_PRO_STAFFING_ROSTER,
            },
            // {
            //   label: translator.t('Contact Lists'),
            //   path: routes.STAFFING_PRO_CONTACT_LIST,
            // },
          ]
        : [
            {
              label: translator.t('ProSearch'),
              path: routes.STAFFING_PRO_SEARCHES,
            },
            // {
            //   label: translator.t('Contact Lists'),
            //   path: routes.STAFFING_PRO_CONTACT_LIST,
            // },
          ],
  },
  {
    label: translator.t('My Network'),
    path: routes.MY_NETWORK,
    icon: 'my-network',
    secondaryLinks: [
      {
        label: translator.t('Connections'),
        path: routes.MY_NETWORK_CONNECTIONS,
      },
      {
        label: translator.t('Connection Requests'),
        path: routes.MY_NETWORK_CONNECTION_REQUESTS,
      },
      {
        label: translator.t('Referral Invites'),
        path: routes.MY_NETWORK_REFERRAL_INVITES,
      },
      {
        label: translator.t('Followed Companies'),
        path: routes.MY_NETWORK_COMPANIES,
      },
      {
        label: translator.t('Contact Lists'),
        path: routes.MY_NETWORK_CONTACT_LISTS,
      },
    ],
  },
  // {
  //   label: 'Member Perks',
  //   icon: 'member-perks',
  //   path: routes.MEMBER_PERKS,
  // },
  {
    label: 'Greenlight Studio',
    icon: 'greenlight-studio',
    path: routes.GREENLIGHT_STUDIO,
    secondaryLinks: [
      {
        label: translator.t('Script Assessment'),
        path: routes.GREENLIGHT_SUITE_ASSESSMENT_RESULT_ROUTE,
        disabled: true
      },
    ],
  },
  // {
  //   label: 'GreenLight Studio',
  //   icon: 'member-perks',
  //   path: routes.GREENLIGHT_STUDIO,
  //   secondaryLinks: [
  //     {
  //       label: translator.t('Script Assessment'),
  //       path: routes.GREENLIGHT_SCRIPT_ASSESSMENT,
  //     },
  //     {
  //       label: translator.t('Pitch Assessment'),
  //       path: routes.GREENLIGHT_PITCH_ASSESSMENT,
  //     },
  //     {
  //       label: translator.t('Outline Collaborator'),
  //       path: routes.GREENLIGHT_OUTLINE_COLLABORATOR,
  //     },
  //   ],
  // },
  // marketPlaceRoute,
  // aboutusNavLinks,
];

export const communicationNavLinks: Omit<NavLinkProps, 'label'>[] = [
  {
    path: routes.MESSAGES,
    icon: 'message',
  },
  {
    path: routes.NOTIFICATIONS,
    icon: 'notification',
  },
];

export const publicNavLinks: PublicNavLinkProps[] = [
  {
    label: 'About Us',
    path: '/Info/AboutUs',
  },
  {
    label: 'Join Our Team',
    path: '/Info/JoinUs',
  },
  {
    label: 'Blog',
    // path: 'https://medium.com/@Husslup',
    path: '/Blog',
    external: true,
  },
  {
    label: 'Podcast',
    path: 'http://podcast.husslup.com',
    external: true,
  },
  {
    label: 'Greenlight Studio',
    path: '/GreenlightStudio.',
  },
];

export const profileNavLinks: NavLinkProps[] = [
  {
    label: translator.t('Edit Personal Profile'),
    path: routes.PROFILE_EDIT_PERSONAL_PROFILE,
    icon: 'edit',
  },
  {
    label: translator.t('Add Company Profile'),
    path: routes.PROFILE_ADD_COMPANY_PROFILE,
    icon: 'plus',
  },
  {
    label: 'Manage Account',
    path: routes.PROFILE_MANAGE_ACCOUNT,
    icon: 'gear',
  },
  {
    label: translator.t('Help and Info'),
    path: routes.HELP_INFO,
    icon: 'tabler_help',
  },
];

export const helpAndInfoLinks: Omit<NavLinkProps, 'icon'>[] = [
  {
    label: 'FAQ',
    path: routes.HELP_INFO_FAQ,
  },
  {
    label: 'Terms of Service',
    path: routes.HELP_INFO_TERMS_OF_SERVICE,
  },
  {
    label: 'Community Guidelines',
    path: routes.HELP_INFO_COMMUNITY_GUIDELINES,
  },
  {
    label: 'Privacy Policy',
    path: routes.HELP_INFO_PRIVACY_POLICIES,
  },
  {
    label: 'Contact Us',
    path: routes.HELP_INFO_CONTACT_US,
  },
];

export const manageAccountLinks: Omit<NavLinkProps, 'icon'>[] = [
  {
    label: 'Edit Profile',
    path: routes.PROFILE_EDIT_PERSONAL_PROFILE,
  },
  {
    label: 'Edit Company Profile',
    path: routes.COMPANY_EDIT,
  },
  {
    label: 'Add Company Profile',
    path: routes.COMPANY_ADD,
  },
  {
    label: 'Manage Account',
    path: routes.PROFILE_MANAGE_ACCOUNT,
  },
  {
    label: 'Help and Info',
    path: routes.HELP_INFO,
    secondaryLinks: helpAndInfoLinks,
  },
];

export const subscriptionLinks: Omit<NavLinkProps, 'icon'>[] = [
  {
    label: 'Subscription',
    path: routes.SUBSCRIPTION_MANAGE_SUBSCRIPTION,
  },
  {
    label: 'Payment Information',
    path: routes.SUBSCRIPTION_PAYMENT_INFORMATION,
  },
  {
    label: 'Manage Seats',
    path: routes.SUBSCRIPTION_MANAGE_SEATS,
  },
];
